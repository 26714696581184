<template>
  <div class="member-wrapper" v-if="member">

    <div class="grid mx-3">

      <v-row>
        <!-- Status -->
        <v-col class="label" sm="3">
          {{ $t('status') }} :
        </v-col>
        <v-col>
          {{ member.status ? member.status : '-' }}
          <v-btn class="ml-5" small :to="{name: 'member.edit'}">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('edit')}}
          </v-btn>
        </v-col>
      </v-row>


      <v-row>
        <v-col class="label" sm="3">
          {{ $t('organization.organization') }} :
        </v-col>
        <v-col sm="9">
          <router-link :to="{name: 'organizations.detail', params: {id: member.organization.id }}" v-if="member.organization">
            {{ member.organization.name}}
          </router-link>
          <template v-else>-</template>
        </v-col>
      </v-row>

      <v-row>
        <!-- First name -->
        <v-col class="label">
          {{ $t('firstname') }} :
        </v-col>
        <v-col>
          {{ member.firstname }}
        </v-col>

        <!-- Last name -->
        <v-col class="label">
          {{ $t('lastname') }} :
        </v-col>
        <v-col>
          {{ member.lastname }}
        </v-col>
      </v-row>

      <v-row>
        <!-- Matricule -->
        <v-col class="label">
          {{ $t('member.external-reference') }}
        </v-col>
        <v-col>
          {{ member.externalReference }}
        </v-col>

        <!-- Plaque d'imatriculation -->
        <v-col class="label">
          {{ $t('vehicle-registration') }}
        </v-col>
        <v-col>
          {{ member.vehicleRegistrationNumber }}
        </v-col>
      </v-row>



      <v-row>
        <v-col class="label"  sm="3">
          {{ $tc('member-type.title') }}
        </v-col>
        <v-col>
          {{ member.type?.name }}
        </v-col>
        <v-col class="label"  sm="3">
          {{ $tc('evco-id') }}
        </v-col>
        <v-col>
          <router-link v-if="currentUser.isZE" :to="{name: 'customer.detail', params: {id: member.customer.id}}">
            {{ member.customer.evcoId }}
          </router-link>
          <template v-else>
            {{ member.customer.evcoId }}
          </template>
        </v-col>
      </v-row>

      <v-row>
        <!-- Identifiant de l'invitation -->
<!--        <v-col class="label"  sm="3">-->
<!--          {{ $tc('member.invitation.identifier') }}-->
<!--        </v-col>-->
<!--        <v-col>-->
<!--          {{ member.invitationIdentifier }}-->
<!--        </v-col>-->

        <!-- Date de création -->
        <v-col class="label"  sm="3">
          {{ $tc('created-at') }}
        </v-col>
        <v-col>
          {{ member.createdAt | formatDate }}
        </v-col>
      </v-row>

    </div>



  </div>

</template>

<script>


import MemberInvitationRepository from "@repository/MemberInvitationRepository";
import Tools from "@includes/tools";

export default {
  components: {


  },

  data() {
    return {

    }
  },

  mixins: [

  ],

  mounted() {

  },

  watch: {

  },

  computed: {
    Tools() {
      return Tools
    },


    member() {
      return this.$parent.member ?? null
    },
  },

  methods: {

  }
}
</script>

<style lang="scss">
.member-wrapper {

  .no-border-bottom {
    border-bottom: none !important;
  }
}
</style>
