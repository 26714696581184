<template>
  <div class="member-group  ">
    <headline class="mt-10">
      {{ $tc('member.home-charging-point', 2) }}
      <v-btn small class="right" @click="openAddChargingPointModal">`
        <v-icon small left>ico-charging-point</v-icon>
        {{ $t('member.add-home-charging-point') }}
      </v-btn>
    </headline>

    <v-row>
      <v-col class="text-center">
        <v-chip
            v-for="(cp, i) in member.homeChargingPoints"
            :key="i"
            :to="cp.ownedByOrganization ? {name: 'charging-points.detail', params: {id: cp.id}} : null"
            class="mr-2"
        >
          {{ cp.name }}
        </v-chip>
        <div v-if="!member.homeChargingPoints?.length" class="font-weight-light font-italic">
          {{ $t('member.no-home-charging-point') }}
        </div>

      </v-col>
    </v-row>

    <template>
      <headline class="mt-12">
        {{ $tc('meter-device.meter-device', 2) }}
        <v-btn small class="right" @click="openAttachMeterDevice">`
          <v-icon small left>mdi-counter</v-icon>
          {{ $t('meter-device.add') }}
        </v-btn>
      </headline>
      <v-row v-if="member.meterDevices?.length > 0">
        <v-col class="text-center">
          <v-chip
              v-for="md in member.meterDevices"
              :key="md.id"
              color="success"
              text-color="white"
              class="mx-2"
              close
              @click:close="deleteMeterDevice(md)"
              :to="{name: 'meter-device.detail', params: {id: md.id}}"
          >
            {{ md.name }} ({{ $t('enums.meter-device.' + md.type) }})
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <div class="font-weight-light font-italic">
            {{ $t('meter-device.no-home-meter-device') }}
          </div>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showAddChargingPointModal" max-width="800">
      <v-card>
        <v-card-title>{{ $t('member.add-home-charging-point') }}</v-card-title>
        <v-card-text>
          <v-autocomplete
              v-model="selectedChargingPoint"
              :search-input.sync="searchChargingPointQuery"
              :items="foundedChargingPointsCleaned"
              :placeholder="$t('member.search-home-charging-point')"
              no-filter
              hide-no-data
              hide-details
              :loading="searchChargingPointLoading"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAttachMeterDeviceModal" width="800">
      <v-card>
        <v-card-title>{{ $t('meter-device.search-placeholder') }}</v-card-title>
        <v-card-text>
          <meter-device-select
              :label="$tc('meter-device.meter-device', 2)"
              v-model="meterDeviceId"
              :filter-query="{
               // organizationId: member.organization.id,//inutile
                //organizationIds: this.currentUser.organizations,
                isNotCustomerId:true
              }"
          />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
              @click="attachMeterDevice"
              :disabled="!meterDeviceId"
              small
          >
            <v-icon left>mdi-counter</v-icon>
            {{ $t('meter-device.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import ChargingPointRepository from "@repository/ChargingPointRepository";
import MemberRepository from "@repository/MemberRepository";
import MeterDeviceSelect from "@blocks/Select/MeterDeviceSelect.vue";
import MeterDeviceRepository from "@repository/MeterDeviceRepository";

export default {
  components: {
    MeterDeviceSelect
  },

  data() {
    return {
      searchChargingPointLoading: false,
      showAddChargingPointModal: false,
      showAttachMeterDeviceModal: false,
      searchChargingPointQuery: null,
      selectedChargingPoint: null,
      foundedChargingPoints: [],
      meterDeviceId: null,
    }
  },


  mounted() {
    if (!this.currentUser.permissions.includes('ORGANIZATION_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

  },

  watch: {
    searchChargingPointQuery(val) {
      this.searchChargingPoints(val)
    },

    selectedChargingPoint(id) {
      if (id) {
        this.attachChargingPoint(id)
      }
    },
  },

  computed: {

    member() {
      return this.$parent.member ?? null
    },

    foundedChargingPointsCleaned() {
      return this.foundedChargingPoints.map(cp => ({value: cp.id, text: `${cp.name} (${cp.identifier})`}))
    },
  },

  methods: {

    openAddChargingPointModal() {
      this.showAddChargingPointModal = true
    },
    openAttachMeterDevice() {
      this.showAttachMeterDeviceModal = true
    },
    attachMeterDevice() {
      if (this.member.customer.id === undefined) {
        this.notifyError(this.$t('customer-not-selected'))
        return
      }
      let data = {
        customerId: this.member.customer.id,
        memberId: this.member.id,
        meterDeviceId: this.meterDeviceId
      }
      MeterDeviceRepository.attachMeterDevice(data).then(response => {
        this.$parent.loadData()
        this.showLoading(false)
        this.notifySuccess(this.$t('update-success'))
        this.showAttachMeterDeviceModal = false
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err.response.data?.message || err.message)
      })
    },
    searchChargingPoints(query) {
      if (query) {
        this.searchChargingPointLoading = true
        ChargingPointRepository.search({query, limit: -100, light: true}).then(result => {
          this.searchChargingPointLoading = false
          this.foundedChargingPoints = result.items
        }).catch(err => {
          this.notifyError(err.response.data?.message || err.message)
          this.searchChargingPointLoading = false
        })
      }
    },

    attachChargingPoint(cpId) {
      this.showLoading(true)
      this.showAddChargingPointModal = false
      this.selectedChargingPoint = null
      this.searchChargingPointQuery = null
      MemberRepository.attachChargingPoint(this.member.id, cpId).then(() => {
        this.$parent.loadData()
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err.response.data?.message || err.message)
        this.showLoading(false)
      })
    },

    deleteChargingPoint(chargingPoint) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          MemberRepository.detachChargingPoint(this.member.id, chargingPoint.id).then(() => {
            this.$parent.loadData()
            this.showLoading(false)
          }).catch(err => {
            this.notifyError(err.response.data?.message || err.message)
            this.showLoading(false)
          })
        }
      })
    },
    deleteMeterDevice(meterDevice){
      let data = {
        customerId: this.member.customer.id,
        memberId: this.member.id,
        meterDeviceId: meterDevice.id
      }
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          MeterDeviceRepository.detachMeterDevice(data).then(() => {
            this.$parent.loadData()
            this.showLoading(false)
            this.notifySuccess(this.$t('update-success'))

          }).catch(err => {
            this.notifyError(err.response.data?.message || err.message)
            this.showLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
