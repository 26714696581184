<template>
  <div class="member-group  ">
    <headline class="mt-10">
      {{ $tc('rfid.rfid', 2) }}
    </headline>
    <v-row>
      <v-col>
        <tokens-manager
            :customer-id="member.customer.id"
            :organization-id="member.organization.id"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import TokensManager from "@/components/elements/TokensManager.vue";

export default {
  components: {
    TokensManager,
  },

  data() {
    return {
    }
  },


  mounted() {
    if(!this.currentUser.permissions.includes('ORGANIZATION_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

  },

  watch: {

  },

  computed: {
    member() {
      return this.$parent.member ?? null
    },
  },

  methods: {


  }
}
</script>

<style lang="scss">

</style>
