<template>
  <div class="member-group  ">
    <headline class="mt-10">
      {{ $t('member-group.groups') }}
    </headline>
    <v-row>
      <v-col>
        <members-groups-associations
            :member="member.id"
            :organization-id="member.organization.id"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MembersGroupsAssociations from "@/components/modals/MembersGroupsAssociations.vue";

export default {
  components: {
    MembersGroupsAssociations,
  },

  data() {
    return {
    }
  },


  mounted() {
    if(!this.currentUser.permissions.includes('ORGANIZATION_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

  },

  watch: {

  },

  computed: {

    member() {
      return this.$parent.member ?? null
    },
  },

  methods: {


  }
}
</script>

<style lang="scss">

</style>
