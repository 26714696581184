<template>
  <div class="member-wrapper pa-10 pt-0" v-if="member">

    <tab-navigation
        :title="$tc('member.member')"
        icon="mdi-account"
        :breadcrumbs="[
            {text: $t('organization.organizations'), to: {name: 'organizations.search'} },
            {text: member.organization.name, to: {name: 'organizations.members', params: {id: member.organization.id}}, exact: true},
            // {text: $tc('member.member', 2), to: {name: 'organizations.members', params: {id: member.organization.id}}, exact: true},
            {text:  memberLabel },
        ]"
    >
      <v-tabs>
        <v-tab :to="{name: 'member'}" exact>{{ $t('information') }}</v-tab>
        <v-tab :to="{name: 'member.groups'}">{{ $tc('group', 2) }}</v-tab>
        <v-tab :to="{name: 'member.tokens'}">{{ $tc('rfid.rfid', 2) }}</v-tab>
        <v-tab :to="{name: 'member.charging-points'}">{{ $t('charging-point.charging-points') }}</v-tab>
        <v-tab :to="{name: 'member.usages'}">{{ $t('charging-point.usage') }}</v-tab>
      </v-tabs>
    </tab-navigation>





    <v-divider class="mb-6" />


    <router-view></router-view>


  </div>

</template>

<script>

import MemberRepository from "@repository/MemberRepository";
import MemberTypeRepository from "@repository/MemberTypeRepository";
import TabNavigation from "@blocks/TabNavigation.vue";

export default {
  components: {
    TabNavigation

  },

  data() {
    return {
      member: null,
      types: [],
    }
  },


  mounted() {
    if(!this.currentUser.permissions.includes('ORGANIZATION_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    this.loadData()
  },

  watch: {

  },

  computed: {

    memberId() {
      return this.$route.params.memberId
    },

    memberLabel() {
      let tmp = []
      if(this.member?.firstname) {
        tmp.push(this.member.firstname)
      }
      if(this.member?.lastname) {
        tmp.push(this.member.lastname)
      }
      if(tmp.length === 0) {
        tmp.push(this.member?.customer.evcoId)
      }

      return tmp.join(' ')
    }

  },

  methods: {
    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        MemberRepository.get(this.memberId).then(data => {
          if(data.type === null) {
            data.type = {
              id: null,
            }
          }
          if(!data.address) {
            data.address = {}
          }
          this.member = data
          this.showLoading(false)
          resolve(data)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data?.message || err.message)
          this.showLoading(false)
          reject(err)
        })
      })
    },
  }
}
</script>

<style lang="scss">
.member-wrapper {

  .no-border-bottom {
    border-bottom: none !important;
  }

  //.v-tabs {
  //  .v-tab {
  //    position: relative;
  //    border-bottom: 1px solid #ccc;
  //    &.v-tab--active {
  //      border-width: 1px 1px 0 1px;
  //      border-style: solid;
  //      border-radius: 4px 4px 0px 0px;
  //      border-color: #ccc;
  //      border-bottom: 0;
  //
  //    }
  //  }
  //}
}
</style>
