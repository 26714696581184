<template>
  <div class="member-wrapper">

    <v-form ref="form" @submit.prevent="save">

      <div class="grid">
        <v-row v-if="form.customer">
          <v-col class="label" sm="3">
            {{ $t('status') }} :
          </v-col>
          <v-col>
            {{ form.status ? form.status : '-' }}
            <v-btn class="ml-5" small @click="disableMember()" v-if="form.status && form.status.toLowerCase() === 'active'">
              <v-icon left>mdi-account-cancel</v-icon>
              {{ $t('disable')}}
            </v-btn>
            <v-btn class="ml-5" small @click="enableMember()" v-else-if="form.status && form.status.toLowerCase() === 'inactive'">
              <v-icon left>mdi-account-check</v-icon>
              {{ $t('do-enable')}}
            </v-btn>
          </v-col>
        </v-row>



        <!-- Type -->
        <v-row>
          <v-col class="label" sm="3">
            {{ $t('type') }}
          </v-col>
          <v-col sm="9">
            <v-select
                v-model="form.type.id"
                :items="types"
                item-text="name"
                item-value="id"
            ></v-select>
          </v-col>
        </v-row>



        <!-- First name -->
        <v-row>
          <v-col class="label" sm="3">
            {{ $t('firstname') }}
          </v-col>
          <v-col class="label" sm="3">
            <v-text-field
                v-model="form.firstname"
                v-capitalize
            />
          </v-col>

          <v-col class="label" sm="3">
            {{ $t('lastname') }}
          </v-col>
          <v-col class="label" sm="3">
            <v-text-field
                v-model="form.lastname"
                v-uppercase
            />
          </v-col>
        </v-row>

        <!-- Matricule -->
        <v-row>
          <v-col class="label" sm="3">
            {{ $t('member.external-reference') }}
          </v-col>
          <v-col class="label" sm="3">
            <v-text-field
                v-model="form.externalReference"
            />
          </v-col>
          <v-col class="label" sm="3">
            {{ $t('vehicle-registration') }}
          </v-col>
          <v-col class="label" sm="3">
            <v-text-field
                v-model="form.vehicleRegistrationNumber"
            />
          </v-col>
        </v-row>

        <v-row v-if="displayIban">
          <v-col class="label" sm="3">
            {{ $t('iban.iban') }} :
          </v-col>
          <v-col>
            <v-text-field
                class="no-border-bottom"
                v-model="form.iban"
                clearable
            />
          </v-col>
          <v-col class="label flex-grow-0 text-no-wrap">
            {{ $t('iban.bic') }} :
          </v-col>
          <v-col>
            <v-text-field
                class="no-border-bottom"
                v-model="form.bic"
                clearable
            />
          </v-col>
          <v-col class="justify-end">
            <v-btn small type="button" @click="sendIbanRegistrationRequest">
              {{ form.isIbanRequestSent ? $t('member.iban-request-sent') : $t('member.send-iban-registration-request')}}
            </v-btn>
          </v-col>
        </v-row>


        <v-row v-else>
          <v-col class="label" sm="3">
            {{ $t('iban.iban') }} / {{ $t('iban.bic') }} :
          </v-col>
          <v-col>
            <v-btn small type="button" @click="handleDisplayIban" class="mr-12">
              {{ $t('show') }}
            </v-btn>
          </v-col>
          <v-col class="justify-end">
            <v-btn small type="button" @click="sendIbanRegistrationRequest">
              {{ form.isIbanRequestSent ? $t('member.iban-request-sent') : $t('member.send-iban-registration-request')}}
            </v-btn>
          </v-col>
        </v-row>



        <template v-if="customFields">
          <headline class="mt-6">{{ $t("settings") }}</headline>
          <custom-fields
              :fields="customFields"
              v-model="form.customFields"
              :col-field="9"
          />
        </template>


        <headline class="mt-10">{{ $t("organization.invoice-information") }}</headline>
        <v-row>
          <v-col class="label" sm="3">
            {{ $t('number') }}
          </v-col>
          <v-col sm="3">
            <v-text-field
                v-model="form.address.number"
                clearable
            />
          </v-col>
          <v-col class="label" sm="3">
            {{ $t('address') }}
          </v-col>
          <v-col sm="3">
            <v-text-field
                v-model="form.address.street"
                clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label" sm="3">
            {{ $t('city') }}
          </v-col>
          <v-col sm="3">
            <v-text-field
                v-model="form.address.city"
                clearable
            />
          </v-col>
          <v-col class="label" sm="3">
            {{ $t('zipcode') }}
          </v-col>
          <v-col  sm="3">
            <v-text-field
                v-model="form.address.zipcode"
                clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label" sm="3">
            {{ $t('state') }}
          </v-col>
          <v-col sm="3">
            <v-text-field
                v-model="form.address.state"
                clearable
            />
          </v-col>
          <v-col class="label" sm="3">
            {{ $t('country') }}
          </v-col>
          <v-col sm="3">
            <available-select
                type="country"
                v-model="form.address.country"
                clearable
            />
          </v-col>
        </v-row>




        <v-row>
          <v-col class="justify-end">
            <v-btn color="primary" type="submit" :disabled="!isFormValid" class="mr-12">
              {{ $t('update') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>

  </div>

</template>

<script>
import RulesMixin from "@mixins/RulesMixin";
import MemberRepository from "@repository/MemberRepository";
import CustomFields from "@blocks/CustomFields.vue";
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";
import MemberTypeRepository from "@repository/MemberTypeRepository";

export default {
  components: {
    CustomFields,
    AvailableSelect,
  },

  data() {
    return {
      edit: false,
      customFields: [],
      typesLoaded: false,
      displayIban: false,
      types: [],
      form: {
        type: {
          id: null,
        },
        address: {},
        vehicleRegistrationNumber: null,
        externalReference: null,
        lastname: null,
        firstname: null,
        groups: [],
        customFields: [],
      },
    }
  },

  mixins: [
    RulesMixin,
  ],

  mounted() {
    if(!this.currentUser.permissions.includes('ORGANIZATION_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.form = this.cloneObj(this.member)

    this.loadTypes()
  },

  watch: {
    'form.type.id': {
      handler(val, oldVal) {
        if(oldVal) {
          this.form.customFields = []
        }
        this.loadCustomFields()
      },
      immediate: true
    },


    member() {
      this.form = this.cloneObj(this.member)
      if(!this.form.address) {
        this.form.address = {}
      }
    },

  },

  computed: {
    member() {
      return this.$parent.member ?? null
    },

    id() {
      return this.$route.params.memberId
    },

    isFormValid() {
      return true
    },

  },

  methods: {

    handleDisplayIban () {
      this.displayIban = true
    },

    loadTypes() {
      let query = {
        organizationId: this.member.organization.id
      }
      MemberTypeRepository.search(query).then(response => {
        this.types = response.items
        this.loadCustomFields()
      })
    },

    loadCustomFields() {
      const selectedType = this.types.find(type => type.id === this.form.type?.id);
      if (selectedType) {
        this.customFields = selectedType.customFields;
      }
    },

    enableMember() { // Change le statut de inactive à active
      this.showLoading(true)
      MemberRepository.changeMemberActiveStatus(this.id, true).then(() => {
        this.showLoading(false)
        this.$parent.loadData()
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    },

    disableMember() { // Change le statut de active à inactive
      this.showLoading(true)
      MemberRepository.changeMemberActiveStatus(this.id, false).then(() => {
        this.$parent.loadData()
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    },

    save() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        let data = this.cloneObj(this.form, ['iban', 'bic', 'customFields', 'externalReference', 'vehicleRegistrationNumber', 'lastname', 'firstname', 'address'])
        data.memberTypeId = this.form.type.id


        MemberRepository.update(this.id, data).then(() => {
          this.$parent.loadData()
          this.notifySuccess('update-success')
        }).catch(err => {
          if(this.$t(`iban.errors.${err.response.data.message}`)) {
            this.$dialog.notify.error(this.$t(`iban.errors.${err.response.data.message}`))
          } else {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
          }
          this.showLoading(false)
        })
      }
    },

    sendIbanRegistrationRequest () {
      let confirmText = this.form.isIbanRequestSent ? this.$t('wanna-sent-new-request') : this.$t('are-you-sure')
      this.$dialog.confirm({text: confirmText}).then(result => {
        if (result) {
          this.showLoading(true)
          MemberRepository.sendIbanRegistrationRequest(this.id).then(() => {
            this.$dialog.notify.success(this.$t('member.iban-registration-request-sent'))
            this.$parent.loadData()
            this.showLoading(false)
          }).catch(err => {
            this.notifyError(err)
            this.showLoading(false)
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">
.member-wrapper {

  .no-border-bottom {
    border-bottom: none !important;
  }
}
</style>
