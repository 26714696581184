<template>
  <div class="member-usage">
    <report-usages-list :organization-id="member.organization.id" :customer-id="member.customer.id"></report-usages-list>
  </div>

</template>

<script>

import ReportUsagesList from "@/components/elements/ReportUsagesList.vue";

export default {
  components: {
    ReportUsagesList

  },

  data() {
    return {

    }
  },


  mounted() {

  },

  watch: {

  },

  computed: {

    member() {
      return this.$parent.member ?? null
    },
  },

  methods: {

  }
}
</script>

<style lang="scss">
.member-wrapper {

  .no-border-bottom {
    border-bottom: none !important;
  }
}
</style>
